@import '../../../../scss/theme-bootstrap';

.product-shade-picker {
  @include pie-clearfix;
  @if $pdp_modernized_design == true {
    margin-top: 24px;
  }
  &__link {
    padding-top: 0.5em;
    text-decoration: none;
    &:hover {
      color: $color-off-black;
    }
  }
  &__shades {
    @include pie-clearfix;
    font-size: 0;
    white-space: nowrap;
    width: 100%;
    margin: 0 -20px -20px -20px;
  }
  &__shade {
    border-radius: 50%;
    float: $ldirection;
    width: 26px;
    height: 26px;
    margin-bottom: 20px;
    margin-#{$ldirection}: 20px;
    &:hover,
    &.active {
      border: 2px solid $color-off-black;
    }
  }
  &__multishade {
    border-radius: 3px;
    float: $ldirection;
    margin-bottom: 20px;
    margin-#{$rdirection}: 20px;
    width: 46px;
    height: 26px;
    display: block;
    overflow: hidden;
    border: 2px solid $white;
    &:hover,
    &.active {
      border-color: $color-off-black;
    }
  }
  &__shade-multiswatch {
    height: 24px;
    display: inline-block;
    margin: 0;
    padding: 0;
    float: $ldirection;
    // shades per multi variations
    // container is 44 - 2 for borders
    // swatches should add up to 42
    //
    // default to duo
    width: 21px; // 21 x 2 = 42,
    // trio
    .multishades--3 & {
      width: 14px; // 14 x 3 = 42,
    }
    // gives flexibility even tho we only have duo and trio for now
  }
  // details section
  &__details {
    div {
      padding-bottom: 0.5em;
      @if $pdp_modernized_design == true {
        width: 100%;
        display: flex;
      }
    }
  }
  &__shadename {
    font-weight: 400;
    font-size: 14px;
  }
  .product-brief__shades & {
    position: relative;
    .carousel-controls {
      height: 26px;
      top: 0;
      .slick-prev,
      .slick-next {
        display: block;
        height: 26px;
        min-width: 0;
        max-width: 15px;
        &:before {
          top: 4px;
          height: 15px;
          width: 15px;
        }
      }
      .slick-prev {
        #{$ldirection}: 0;
        &:before {
          #{$rdirection}: auto;
          #{$ldirection}: 0;
        }
      }
      .slick-next {
        #{$rdirection}: 0;
        &:before {
          #{$rdirection}: 0;
          #{$ldirection}: auto;
        }
      }
    }
    .product-shade-picker__shade-desc,
    .product-shade-picker__color-group {
      display: none;
    }
    .product-shade-picker__slides {
      padding: 0 15px;
    }
    .product-shade-picker__shades,
    .product-shade-picker__multi-shades {
      margin: 0;
      position: relative;
    }
    .product-shade-picker__shades {
      height: 26px;
      overflow: hidden;
      .product-shade-picker__slides {
        height: 26px;
      }
      .product-shade-picker__slide {
        height: 26px;
        text-align: center;
        &:not(.slick-slide) {
          float: $ldirection;
          margin: 0 7px;
        }
      }
    }
    .product-shade-picker__multi-shades {
      height: 26px;
      .carousel-controls {
        height: 26px;
        .slick-prev,
        .slick-next {
          height: 26px;
          &:before {
            top: 4px;
          }
        }
      }
      .product-shade-picker__slides {
        height: 26px;
        overflow: hidden;
      }
      .product-shade-picker__slide {
        height: 26px;
        text-align: center;
        &:not(.slick-slide) {
          float: $ldirection;
          margin-#{$rdirection}: 5px;
        }
      }
    }
    .product-shade-picker__shade,
    .product-shade-picker__multishade {
      margin: 0 auto;
      float: unset;
    }
    .product-shade-picker__shade {
      width: 26px;
      height: 26px;
    }
  }
}
